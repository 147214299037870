import React from 'react';
import './Product.css';

function Product() {
  return (
    <div className="product-page">
      {/* Background element */}
      <div className="background"></div>

      {/* Content container */}
      <div className="product-content">
        <h2 className="coming-soon-box">Coming soon!</h2>
      </div>
    </div>
  );
}

export default Product;
