import React from 'react';
import Solution from './Solution';
import Introduction from './introduction';
import SolutionsGif from './SolutionsGif';


function Home() {
  return (
    <div className="home">
      <Introduction />
      <Solution />
      <SolutionsGif />
    </div>
  );
}

export default Home;