import React from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import './Header.css';
import logoMobile from '../assets/logo_mobile.png';
import logoPc from '../assets/logo_pc.png';

function Header() {
  const location = useLocation();
  const navigate = useNavigate();

  const handlePerspectiveClick = (event, targetPath) => {
    event.preventDefault();
    if (location.pathname === targetPath) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    } else {
      navigate(targetPath);
    }
  };

  return (
    <header className="header">
      <div className="logo">
        <img src={logoMobile} alt="Kon Aerosystems Mobile" className="logo-image logo-mobile" />
        <img src={logoPc} alt="Kon Aerosystems" className="logo-image logo-pc" />
      </div>
      <nav className="nav">
        <Link to="/" className="home-link" onClick={(e) => handlePerspectiveClick(e, '/')}>Home</Link>
        <Link to="/contact" className="contact-link" onClick={(e) => handlePerspectiveClick(e, '/contact')}>Contact</Link>
        <Link to="/product" className="product-link" onClick={(e) => handlePerspectiveClick(e, '/product')}>Technology</Link> {/* Ensure correct path */}
      </nav>
    </header>
  );
}

export default Header;
