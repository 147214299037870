import React from 'react';
import './introduction.css';

// Introduction.jsx
function Introduction() {
  return (
    <section className="introduction">
      <h1 className="tagline">
        Revolutionizing Aviation <br />
        <span className="highlight">One Take-Off at a Time</span>
      </h1>
      <p className="intro-text">
      We are transforming aviation with electromagnetic propulsion technology, inspired by maglev and hyperloop systems, to reduce fuel consumption and CO2 emissions during the most energy-intensive phase of flight.
      </p>
    </section>
  );
}



export default Introduction;
