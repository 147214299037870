import React, { useRef, useEffect } from 'react';
import './Solution.css';

function Solution() {
  const solutionRef = useRef(null);

  useEffect(() => {
    const observerOptions = {
      threshold: 0.3, // Trigger when 30% of the section is visible
    };

    const observerCallback = (entries, observer) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.classList.add('active');
          observer.unobserve(entry.target); // Stop observing after activation
        }
      });
    };

    const observer = new IntersectionObserver(observerCallback, observerOptions);
    const currentElement = solutionRef.current;

    if (currentElement) {
      observer.observe(currentElement);
    }

    return () => {
      if (currentElement) {
        observer.unobserve(currentElement);
      }
    };
  }, []);

  return (
    <section className="solution" ref={solutionRef}>
      <div className="solution-content">
        <div className="glowing-box">
          <div className="solution-title">
            <div className="etols">
              <span>eTOLS</span>
            </div>
            <div className="solution-subtitle">
              <span>Electromagnetic Take-Off Launch System</span>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Solution;
