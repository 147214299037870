import React, { useRef, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import './SolutionsGif.css';
import DroneImage from '../assets/Drone_image.png';
import AirplaneV1 from '../assets/AirplaneV1.png';
import AirplaneV2 from '../assets/AirplaneV2.png';

function SolutionsGif() {
  const solutionsRef = useRef(null);
  const [selectedStep, setSelectedStep] = useState(null);

  useEffect(() => {
    const observerOptions = { threshold: 0.3 };

    const observerCallback = (entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add('active');
          observer.unobserve(entry.target);
        }
      });
    };

    const observer = new IntersectionObserver(observerCallback, observerOptions);
    const currentElement = solutionsRef.current;

    if (currentElement) {
      observer.observe(currentElement);
    }

    return () => {
      if (currentElement) {
        observer.unobserve(currentElement);
      }
    };
  }, []);

  const handleImageClick = (step) => {
    setSelectedStep((prevStep) => (prevStep === step ? null : step));
  };

  // Close popup when scrolling away
  useEffect(() => {
    const handleScroll = () => setSelectedStep(null);
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <section className="solutions-gif" ref={solutionsRef}>
      <div className="solutions-container">
        {/* eTOLS V1 - Drones */}
        <div className="solution-step">
          <h3 className="solution-type">eTOLS V1</h3>
          <div className="step-image" onClick={() => handleImageClick(1)}>
            <img src={DroneImage} alt="Drones eTOLS V1" className="clickable-image" />
          </div>
          <p className="solution-name">Drones</p>
          {selectedStep === 1 && (
            <div className="popup-overlay">
              <div className="popup-content">
                <button className="close-popup" onClick={() => setSelectedStep(null)}>X</button>
                <p>
                  eTOLS V1 is designed for drone operations, optimizing launch efficiency and energy consumption.
                </p>
                <Link to="/product" className="learn-more">
                  LEARN MORE
                </Link>
              </div>
            </div>
          )}
        </div>

        {/* eTOLS V2 - Commercial Aviation */}
        <div className="solution-step">
          <h3 className="solution-type">eTOLS V2</h3>
          <div className="step-image" onClick={() => handleImageClick(2)}>
            <img src={AirplaneV1} alt="Commercial Aviation eTOLS V2" className="clickable-image" />
          </div>
          <p className="solution-name">Commercial Aviation</p>
          {selectedStep === 2 && (
            <div className="popup-overlay">
              <div className="popup-content">
                <button className="close-popup" onClick={() => setSelectedStep(null)}>X</button>
                <p>
                  eTOLS V2 is designed for commercial aviation, reducing emissions and improving take-off efficiency.
                </p>
                <Link to="/product" className="learn-more">
                  LEARN MORE
                </Link>
              </div>
            </div>
          )}
        </div>

        {/* eTOLS V3 - Electric Aircrafts */}
        <div className="solution-step">
          <h3 className="solution-type">eTOLS V3</h3>
          <div className="step-image" onClick={() => handleImageClick(3)}>
            <img src={AirplaneV2} alt="Electric Aircrafts eTOLS V3" className="clickable-image" />
          </div>
          <p className="solution-name">Electric Aircrafts</p>
          {selectedStep === 3 && (
            <div className="popup-overlay">
              <div className="popup-content">
                <button className="close-popup" onClick={() => setSelectedStep(null)}>X</button>
                <p>
                  eTOLS V3 represents the future of electric aircraft with zero-emission take-off technology.
                </p>
                <Link to="/product" className="learn-more">
                  LEARN MORE
                </Link>
              </div>
            </div>
          )}
        </div>
      </div>
    </section>
  );
}

export default SolutionsGif;
